<template>
  <v-container fluid class="mx-auto">
    <side-menu :main-page="$route.name" />
    <div style="padding: 0 139px 150px 395px">
      <v-img
        :src="require('@/assets/logo.svg')"
        class="mt-16 mb-5"
        contain
        height="38"
      >
      </v-img>
      <p class="blue-grey--text text-center mb-12">
        Repository of Vocabularies and Schemas. β1.0
      </p>

      <div style="width: 550px" class="mx-auto">
        <v-btn x-large block outlined color="#8F5F98" @click="movePage">
          {{ getWords("type_add") }}
        </v-btn>
      </div>
    </div>
  </v-container>
</template>

<script>
import SideMenu from "../../components/SideMenu";
import words from "../../assets/localewords.json";

export default {
  name: "Type",
  components: { SideMenu },
  data: () => ({
    links: [
      { label: "Vocabulary" },
      { label: "Schema" },
      { label: "Terms" },
      { label: "Namespace" },
    ],
  }),
  computed: {
    getLanguage() {
      return this.$store.state.language;
    },
  },
  methods: {
    labelSelected(type) {
      const selectedItem = this.links[type];
      if (selectedItem.label === "Vocabulary") {
        this.$router.push({ name: "Create" }).catch((err) => console.log(err));
      }
    },
    movePage() {
      this.$router.push({ name: "Create" }).catch((err) => console.log(err));
    },
    getWords(key) {
      let lang = this.getLanguage;
      if (lang !== "en" && lang !== "ja") {
        lang = "en";
      }
      const word = words[key] ? words[key][lang] : "";
      return word;
    },
  },
};
</script>

<style scoped></style>
